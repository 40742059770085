import { useEffect } from "react";
import { Card } from "@mui/material";
import { Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const fakeData = {
  adminSettingId: "1",
  feePercentage: 2.0, // Example percentage
  minimumFee: 100, // Example minimum fee in currency unit
};

function Setting(): JSX.Element {
  const [form] = useForm();

  const putAdministratorSetting = (e: any) => {
    const { adminSettingId, feePercentage, minimumFee } = e;

    // Simulating an API call with a Promise
    new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (adminSettingId && feePercentage && minimumFee) {
          console.log("Settings updated:", {
            adminSettingId,
            feePercentage: parseFloat(feePercentage),
            minimumFee: parseFloat(minimumFee),
          });
          resolve();
        } else {
          reject(new Error("Invalid data"));
        }
      }, 1000); // Simulate a network delay
    })
      .then(() => {
        message.success("Settings updated successfully");
      })
      .catch((error) => {
        console.error(error);
        message.error("Error updating settings");
      });
  };

  useEffect(() => {
    const { adminSettingId, feePercentage, minimumFee } = fakeData;
    form.setFieldsValue({
      adminSettingId,
      feePercentage: feePercentage.toString(),
      minimumFee: minimumFee.toString(),
    });
  }, [form]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Form form={form} onFinish={putAdministratorSetting}>
              <Form.Item hidden name="adminSettingId">
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="feePercentage"
                label="수수료 비율 (%)"
                extra="수수료 비율은 거래 금액의 일정 비율입니다. 예: 2% 입력 시 거래 금액의 2%가 수수료로 부과됩니다."
                rules={[{ required: true, message: "수수료 비율을 입력하세요." }]}
              >
                <Input type="number" min="0" step="0.01" />
              </Form.Item>
              <Form.Item
                name="minimumFee"
                label="최소 수수료 (원)"
                extra="최소 수수료는 거래 금액에 관계없이 부과되는 최소 금액입니다. 예: 최소 수수료가 100원인 경우 거래 금액이 100원 이하라도 수수료는 100원입니다."
                rules={[{ required: true, message: "최소 수수료를 입력하세요." }]}
              >
                <Input type="number" min="0" step="10" />
              </Form.Item>
              <Form.Item>
                <MDButton type="submit" color="info" variant="gradient">
                  변경
                </MDButton>
              </Form.Item>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Setting;
