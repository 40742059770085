import dayjs from "dayjs";

/** createAt, updatedAt 등 date string format function */
function formatDate(dateString: string) {
  const date = dayjs(dateString);
  if (date.isValid()) {
    return date.format("YYYY.MM.DD");
  } else {
    return "Invalid date";
  }
}

/** 나이 계산기 */
function calculateAge(birthdate: string) {
  try {
    // 입력된 생년월일을 Date 객체로 변환합니다.
    const birthDate = new Date(birthdate);
    const today = new Date();

    // 나이 계산
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // 오늘 날짜가 생일 이전이라면 나이에서 1을 빼줍니다.
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return isNaN(age) ? "미입력" : age;
  } catch (error) {
    return "미입력";
  }
}

export { formatDate, calculateAge };
