import { Divider, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { message } from "antd";

import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/es/input/Input";
import Apis from "apis/remotes";
import { IGetAdministratorMy } from "apis/response";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import ListItem from "./components/ListItem";
import { handleInput } from "utils/PhoneString";

function MyProfile(): JSX.Element {
  const navigate = useNavigate();
  const [myProfile, setMyProfile] = useState<IGetAdministratorMy>();
  const [form] = useForm();

  const handleUpdateMyProfile = (e: FormData) => {
    // "nickName": "string",
    // "role": "master",
    // "phone": "string"
    Apis.putAdministrator(e)
      .then((resolve) => {
        message.success("내 정보 수정");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogOut = () => {
    Apis.deleteAuth()
      .then((resolve) => {
        message.info("로그아웃 되었습니다.");
        navigate("/authentication/sign-in/basic", { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Apis.getAdministratorMy()
      .then((resolve) => {
        setMyProfile(resolve.data);
        form.setFieldsValue({
          ...resolve.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <MDBox>
              <ListItem label={"아이디"} values={myProfile?.email} />
              <ListItem label={"권한"} values={myProfile?.role} />
              <ListItem label={"권한"} values={myProfile?.role} />
              <ListItem label={"상태"} values={myProfile?.status} />
              <ListItem label={"생성일"} values={myProfile?.createdAt} />
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <Form form={form} onFinish={handleUpdateMyProfile}>
              <FormItem
                label="닉네임"
                name="nickName"
                rules={[
                  {
                    required: true,
                    message: "닉네임을 입력하세요.",
                  },
                ]}
              >
                <Input type="text" maxLength={20} showCount />
              </FormItem>
              <FormItem
                label="연락처"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "연락처를 입력하세요.",
                  },
                ]}
              >
                <Input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                  maxLength={13}
                  onInput={handleInput}
                />
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <MDButton fullWidth variant="gradient" color="info" type="submit">
                    내정보 변경
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
        <MDBox my={3} style={{ float: "right" }}>
          <MDButton variant="gradient" color="dark" onClick={() => handleLogOut()}>
            로그아웃
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default MyProfile;
