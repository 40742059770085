import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { ColumnsType } from "antd/es/table";
import { formatDate } from "utils/utilDate";

interface RechargeTransaction {
  paymentId: number; // 순번
  userId: string; // 아이디
  profile: string; // 충전프로필
  rechargeDate: string; // 충전날짜
  amount: number; // 금액
}
const fakeRechargeTransactions: RechargeTransaction[] = [
  {
    paymentId: 1,
    userId: "user01",
    profile: "ProfileA",
    rechargeDate: "2023-07-01",
    amount: 10000,
  },
  {
    paymentId: 2,
    userId: "user02",
    profile: "ProfileB",
    rechargeDate: "2023-07-05",
    amount: 15000,
  },
  {
    paymentId: 3,
    userId: "user03",
    profile: "ProfileC",
    rechargeDate: "2023-07-10",
    amount: 20000,
  },
  {
    paymentId: 4,
    userId: "user04",
    profile: "ProfileD",
    rechargeDate: "2023-07-15",
    amount: 25000,
  },
  {
    paymentId: 5,
    userId: "user05",
    profile: "ProfileE",
    rechargeDate: "2023-07-20",
    amount: 30000,
  },
  {
    paymentId: 6,
    userId: "user06",
    profile: "ProfileF",
    rechargeDate: "2023-07-25",
    amount: 35000,
  },
  {
    paymentId: 7,
    userId: "user07",
    profile: "ProfileG",
    rechargeDate: "2023-07-30",
    amount: 40000,
  },
  {
    paymentId: 8,
    userId: "user08",
    profile: "ProfileH",
    rechargeDate: "2023-08-01",
    amount: 45000,
  },
  {
    paymentId: 9,
    userId: "user09",
    profile: "ProfileI",
    rechargeDate: "2023-08-05",
    amount: 50000,
  },
  {
    paymentId: 10,
    userId: "user10",
    profile: "ProfileJ",
    rechargeDate: "2023-08-10",
    amount: 55000,
  },
];
const { RangePicker } = DatePicker;
function ChargingHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(fakeRechargeTransactions.length);
  const [data, setData] = useState<RechargeTransaction[]>(fakeRechargeTransactions);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/charging-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/charging-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/charging-history-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      })}`
    );
  };

  const tableColumn: ColumnsType<RechargeTransaction> = [
    {
      title: "순번",
      dataIndex: "paymentId",
      key: "paymentId",
      align: "center",
      sorter: (a, b) => a.paymentId - b.paymentId,
    },
    {
      title: "아이디",
      dataIndex: "userId",
      key: "userId",
      align: "center",
    },
    {
      title: "충전 프로필",
      dataIndex: "profile",
      key: "profile",
      align: "center",
    },
    {
      title: "충전 날짜",
      dataIndex: "rechargeDate",
      key: "rechargeDate",
      align: "center",
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.rechargeDate).getTime() - new Date(b.rechargeDate).getTime(),
    },
    {
      title: "금액",
      dataIndex: "amount",
      key: "amount",
      align: "match-parent",
      render: (amount: number) => amount.toLocaleString(),
      sorter: (a, b) => a.amount - b.amount,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>
        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.paymentId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/pin-money-management/charging-history-details/${record.paymentId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default ChargingHistoryList;
