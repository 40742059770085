import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, Pagination, PaginationProps, Table, Checkbox } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { ColumnsType } from "antd/es/table";
import MDButton from "components/MDButton";
import { TableRowSelection } from "antd/es/table/interface";

interface Transaction {
  transactionId: number;
  transactionType: string;
  transactionStatus: string;
  applicantUserId: string;
  applicantProfile: string;
  requestedAmount: number;
  settledAmount: number;
  settlementType: string;
  bankName: string;
  accountNumber: string;
}

const fakeTransactions: Transaction[] = [
  {
    transactionId: 1,
    transactionType: "출금",
    transactionStatus: "출금신청",
    applicantUserId: "user01",
    applicantProfile: "ProfileA",
    requestedAmount: 100000,
    settledAmount: 98000,
    settlementType: "일반정산",
    bankName: "BankA",
    accountNumber: "123-456-7890",
  },
  {
    transactionId: 2,
    transactionType: "출금",
    transactionStatus: "입금완료",
    applicantUserId: "user02",
    applicantProfile: "ProfileB",
    requestedAmount: 200000,
    settledAmount: 196000,
    settlementType: "즉시정산",
    bankName: "BankB",
    accountNumber: "234-567-8901",
  },
  {
    transactionId: 3,
    transactionType: "출금",
    transactionStatus: "취소",
    applicantUserId: "user03",
    applicantProfile: "ProfileC",
    requestedAmount: 150000,
    settledAmount: 0,
    settlementType: "일반정산",
    bankName: "BankC",
    accountNumber: "345-678-9012",
  },
  {
    transactionId: 4,
    transactionType: "출금",
    transactionStatus: "출금신청",
    applicantUserId: "user04",
    applicantProfile: "ProfileD",
    requestedAmount: 300000,
    settledAmount: 294000,
    settlementType: "즉시정산",
    bankName: "BankD",
    accountNumber: "456-789-0123",
  },
  {
    transactionId: 5,
    transactionType: "출금",
    transactionStatus: "입금완료",
    applicantUserId: "user05",
    applicantProfile: "ProfileE",
    requestedAmount: 250000,
    settledAmount: 245000,
    settlementType: "일반정산",
    bankName: "BankE",
    accountNumber: "567-890-1234",
  },
  {
    transactionId: 6,
    transactionType: "출금",
    transactionStatus: "출금신청",
    applicantUserId: "user06",
    applicantProfile: "ProfileF",
    requestedAmount: 120000,
    settledAmount: 117600,
    settlementType: "즉시정산",
    bankName: "BankF",
    accountNumber: "678-901-2345",
  },
  {
    transactionId: 7,
    transactionType: "출금",
    transactionStatus: "취소",
    applicantUserId: "user07",
    applicantProfile: "ProfileG",
    requestedAmount: 180000,
    settledAmount: 0,
    settlementType: "일반정산",
    bankName: "BankG",
    accountNumber: "789-012-3456",
  },
  {
    transactionId: 8,
    transactionType: "출금",
    transactionStatus: "출금신청",
    applicantUserId: "user08",
    applicantProfile: "ProfileH",
    requestedAmount: 220000,
    settledAmount: 215600,
    settlementType: "즉시정산",
    bankName: "BankH",
    accountNumber: "890-123-4567",
  },
  {
    transactionId: 9,
    transactionType: "출금",
    transactionStatus: "입금완료",
    applicantUserId: "user09",
    applicantProfile: "ProfileI",
    requestedAmount: 170000,
    settledAmount: 166600,
    settlementType: "일반정산",
    bankName: "BankI",
    accountNumber: "901-234-5678",
  },
  {
    transactionId: 10,
    transactionType: "출금",
    transactionStatus: "취소",
    applicantUserId: "user10",
    applicantProfile: "ProfileJ",
    requestedAmount: 210000,
    settledAmount: 0,
    settlementType: "즉시정산",
    bankName: "BankJ",
    accountNumber: "012-345-6789",
  },
];

const { RangePicker } = DatePicker;

function WithDrawalHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(fakeTransactions.length);
  const [data, setData] = useState<Transaction[]>(fakeTransactions);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]); // Explicitly define the type as number[]

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/withdrawal-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/withdrawal-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/withdrawal-history-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      })}`
    );
  };

  const handleSetCompleted = (transactionId?: number) => {
    setData((prevData) =>
      prevData.map((transaction) => {
        if (transactionId) {
          // Update a single transaction
          return transaction.transactionId === transactionId
            ? { ...transaction, transactionStatus: "입금완료" }
            : transaction;
        } else {
          // Update all selected transactions
          return selectedRowKeys.includes(transaction.transactionId)
            ? { ...transaction, transactionStatus: "입금완료" }
            : transaction;
        }
      })
    );
    setSelectedRowKeys([]); // Clear selection
  };

  const rowSelection: TableRowSelection<Transaction> = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys as number[]), // Type assertion
  };

  const tableColumn: ColumnsType<Transaction> = [
    {
      title: "순번",
      dataIndex: "transactionId",
      key: "transactionId",
      align: "center",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "거래종류",
      dataIndex: "transactionType",
      key: "transactionType",
      align: "center",
    },
    {
      title: "거래상태",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      align: "center",
    },
    {
      title: "신청자 (아이디 - 프로필)",
      dataIndex: ["applicantUserId", "applicantProfile"],
      key: "applicant",
      align: "center",
      render: (text, record) => `${record.applicantUserId} - ${record.applicantProfile}`,
    },
    {
      title: "정산신청금액",
      dataIndex: "requestedAmount",
      key: "requestedAmount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
      sorter: (a, b) => a.requestedAmount - b.requestedAmount,
    },
    {
      title: "실정산금액",
      dataIndex: "settledAmount",
      key: "settledAmount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
      sorter: (a, b) => a.settledAmount - b.settledAmount,
    },
    {
      title: "정산종류",
      dataIndex: "settlementType",
      key: "settlementType",
      align: "center",
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
    },
    {
      title: "계좌번호",
      dataIndex: "accountNumber",
      key: "accountNumber",
      align: "center",
    },
    {
      title: "개별 입금완료",
      key: "action",
      render: (_, record) => (
        <MDButton
          size="small"
          color="info"
          onClick={(event) => {
            event.stopPropagation();
            handleSetCompleted(record.transactionId);
          }}
        >
          입금완료
        </MDButton>
      ),
      align: "center",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>

        <Card>
          <Table
            rowSelection={rowSelection}
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.transactionId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(
            //         `/pin-money-management/withdrawal-history-details/${record.transactionId}`
            //       );
            //     },
            //   };
            // }}
            footer={() => (
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDButton color="primary" onClick={() => handleSetCompleted()}>
                  공통 입금완료
                </MDButton>
                <Pagination
                  current={currentPage}
                  onChange={handleOnChangePage}
                  pageSize={10}
                  total={total}
                  showSizeChanger={false}
                />
              </div>
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default WithDrawalHistoryList;
