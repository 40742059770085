/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// import SiteList from "layouts/SiteList";
import MyProfile from "layouts/categories/adminManagement/myprofile";
import SignInBasic from "layouts/authentication/sign-in/basic";
import OnBoards from "layouts/dashboards/onboards";
import Setting from "layouts/categories/settingManagement";
import ChargingHistoryList from "layouts/categories/pinMoneyManagement/chargingHistory/list";
import ChargingHistoryDetails from "layouts/categories/pinMoneyManagement/chargingHistory/details";
import MissionHistoryList from "layouts/categories/pinMoneyManagement/missionHistory/list";
import MissionHistoryDetails from "layouts/categories/pinMoneyManagement/missionHistory/details";
import WithDrawalHistoryList from "layouts/categories/pinMoneyManagement/withDrawalHistory/list";
import WithDrawalHistoryDetails from "layouts/categories/pinMoneyManagement/withDrawalHistory/details";
import MemberDetails from "layouts/categories/memberManagement/members/details";
import MemberList from "layouts/categories/memberManagement/members/list";

const routes = [
  // 대시보드
  {
    name: "로그인",
    key: "basic",
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "대시보드",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <OnBoards />,
    noCollapse: true,
  },
  {
    name: "관리자 계정관리",
    key: "my-profile",
    route: "/my-profile",
    component: <MyProfile />,
    hidden: true,
  },

  // 회원관리
  {
    type: "collapse",
    name: "회원관리",
    key: "member-management",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        // 회원관리
        name: "회원정보",
        key: "member-list",
        route: "/member-management/member-list",
        component: <MemberList />,
      },
      {
        // 회원관리 상세조회

        name: "회원정보 상세조회",
        key: "member-details",
        route: "/member-management/member-details/:id",
        component: <MemberDetails />,
        hidden: true,
      },
    ],
  },
  // 용돈관리
  {
    type: "collapse",
    name: "용돈관리",
    key: "pin-money-management",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        // 충전내역
        name: "충전내역",
        key: "charging-history-list",
        route: "/pin-money-management/charging-history-list",
        component: <ChargingHistoryList />,
      },
      // {
      //   // 충전내역 상세조회
      //   name: "충전내역 상세조회",
      //   key: "charging-history-details",
      //   route: "/pin-money-management/charging-history-details/:id",
      //   component: <ChargingHistoryDetails />,
      //   hidden: true,
      // },

      {
        // 미션/용돈/판매내역
        name: "미션/용돈/판매내역",
        key: "mission-history-list",
        route: "/pin-money-management/mission-history-list",
        component: <MissionHistoryList />,
      },
      // {
      //   // 미션/용돈/판매내역 상세조회
      //   name: "미션/용돈/판매내역 상세조회",
      //   key: "mission-history-details",
      //   route: "/pin-money-management/mission-history-details/:id",
      //   component: <MissionHistoryDetails />,
      //   hidden: true,
      // },

      {
        // 출금내역
        name: "출금내역",
        key: "withdrawal-history-list",
        route: "/pin-money-management/withdrawal-history-list",
        component: <WithDrawalHistoryList />,
      },
      // {
      //   // 출금내역 상세조회
      //   name: "출금내역 상세조회",
      //   key: "withdrawal-history-details",
      //   route: "/pin-money-management/withdrawal-history-details/:id",
      //   component: <WithDrawalHistoryDetails />,
      //   hidden: true,
      // },
    ],
  },
  {
    type: "collapse",
    name: "설정",
    key: "setting",
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/setting",
    component: <Setting />,
    noCollapse: true,
  },
];

export default routes;
