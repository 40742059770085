import { UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import Apis from "apis/remotes";
import { fileUplaodType, fileUploadTarget } from "apis/response";
import axios from "axios";

const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
};

// async function uploadImgToS3(file: ArrayBuffer, type: string, url: string, path: string) {
//   const res = await axios.put(url, file, {
//     headers: {
//       "Content-Type": type,
//     },
//   });
//   if (res.status === 200) {
//     return path;
//   }
//   return "";
// }

async function uploadImgToS3File(file: RcFile, type: string, url: string, path: string) {
  const res = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (res.status === 200) {
    return path;
  }
  return "";
}

const handleUploadCompMultiFiles = async (
  type: fileUplaodType,
  uploadFileList: UploadFile[],
  imageUploadTarget: fileUploadTarget
) => {
  try {
    if (uploadFileList.length === 0) return;
    const paths: string[] = [];
    for (let i = 0; i < uploadFileList.length; i++) {
      if (uploadFileList[i].uid.includes("done file")) {
        paths.push(uploadFileList[i].url!.replace(`${process.env.REACT_APP_IMG_ENDPOINT}`, ""));
      } else {
        // const fileBuffer = await uploadFileList[i].originFileObj?.arrayBuffer();
        let fileType = uploadFileList[i].type?.split("/")[0];
        // console.log(fileType);
        if (fileType === "application") {
          fileType = "file";
        }
        const res = await Apis.postFilesUpload({
          type: type,
          mimeType: uploadFileList[i].type,
          imageUploadTarget: imageUploadTarget,
        });
        // console.log("여기 뭐나오나", res);
        if (res.status === 200) {
          console.log("여기는 200");
          const path = await uploadImgToS3File(
            uploadFileList[i].originFileObj as RcFile,
            uploadFileList[i].type!,
            res.data[0].url,
            res.data[0].path
          );
          // console.log("패스주나?", path);

          // const path = await uploadImgToS3(
          //   fileBuffer!,
          //   uploadFileList[i].type!,
          //   res.data[0].url,
          //   res.data[0].path
          // );
          paths.push(path);
        }
      }
    }

    return paths;
  } catch (error) {
    console.log(error);
  }
};

const startEdit = (imageList: string[], stateFunc: Function) => {
  const imageFileList = imageList;
  const arr = [];
  for (let i = 0; i < imageFileList.length; i++) {
    const previewFile: UploadFile = {
      uid: `done file ${i}`,
      name: imageFileList[i],
      status: "done",
      url: `${process.env.REACT_APP_IMG_ENDPOINT}${imageFileList[i]}`,
    };
    arr.push(previewFile);
  }
  stateFunc(arr);
};

export { props, handleUploadCompMultiFiles, startEdit };
