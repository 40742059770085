import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

import { IGetUsers } from "apis/response";

import { DatePicker, Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";

import { ColumnsType } from "antd/es/table";

interface Transaction {
  transactionId: number; // 순번
  transactionType: "미션" | "용돈" | "판매"; // 거래종류 (미션/용돈/판매)
  senderUserId: string; // 보낸사람 아이디
  senderProfile: string; // 보낸사람 프로필
  receiverUserId: string; // 받은사람 아이디
  receiverProfile: string; // 받은사람 프로필
  sentAmount: number; // 보낸금액
  receivedAmount: number; // 받은금액
  fee: number; // 수수료
}
const fakeTransactions: Transaction[] = [
  {
    transactionId: 1,
    transactionType: "미션",
    senderUserId: "user01",
    senderProfile: "ProfileA",
    receiverUserId: "user02",
    receiverProfile: "ProfileB",
    sentAmount: 5000,
    receivedAmount: 4900,
    fee: 100,
  },
  {
    transactionId: 2,
    transactionType: "용돈",
    senderUserId: "user03",
    senderProfile: "ProfileC",
    receiverUserId: "user04",
    receiverProfile: "ProfileD",
    sentAmount: 10000,
    receivedAmount: 9800,
    fee: 200,
  },
  {
    transactionId: 3,
    transactionType: "판매",
    senderUserId: "user05",
    senderProfile: "ProfileE",
    receiverUserId: "user06",
    receiverProfile: "ProfileF",
    sentAmount: 15000,
    receivedAmount: 14700,
    fee: 300,
  },
  {
    transactionId: 4,
    transactionType: "미션",
    senderUserId: "user07",
    senderProfile: "ProfileG",
    receiverUserId: "user08",
    receiverProfile: "ProfileH",
    sentAmount: 20000,
    receivedAmount: 19800,
    fee: 200,
  },
  {
    transactionId: 5,
    transactionType: "용돈",
    senderUserId: "user09",
    senderProfile: "ProfileI",
    receiverUserId: "user10",
    receiverProfile: "ProfileJ",
    sentAmount: 25000,
    receivedAmount: 24750,
    fee: 250,
  },
  {
    transactionId: 6,
    transactionType: "판매",
    senderUserId: "user02",
    senderProfile: "ProfileB",
    receiverUserId: "user03",
    receiverProfile: "ProfileC",
    sentAmount: 30000,
    receivedAmount: 29700,
    fee: 300,
  },
  {
    transactionId: 7,
    transactionType: "미션",
    senderUserId: "user04",
    senderProfile: "ProfileD",
    receiverUserId: "user05",
    receiverProfile: "ProfileE",
    sentAmount: 35000,
    receivedAmount: 34500,
    fee: 500,
  },
  {
    transactionId: 8,
    transactionType: "용돈",
    senderUserId: "user06",
    senderProfile: "ProfileF",
    receiverUserId: "user07",
    receiverProfile: "ProfileG",
    sentAmount: 40000,
    receivedAmount: 39600,
    fee: 400,
  },
  {
    transactionId: 9,
    transactionType: "판매",
    senderUserId: "user08",
    senderProfile: "ProfileH",
    receiverUserId: "user09",
    receiverProfile: "ProfileI",
    sentAmount: 45000,
    receivedAmount: 44500,
    fee: 500,
  },
  {
    transactionId: 10,
    transactionType: "미션",
    senderUserId: "user10",
    senderProfile: "ProfileJ",
    receiverUserId: "user01",
    receiverProfile: "ProfileA",
    sentAmount: 50000,
    receivedAmount: 49500,
    fee: 500,
  },
];

const { RangePicker } = DatePicker;
function MissionHistoryList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(fakeTransactions.length);
  const [data, setData] = useState<Transaction[]>(fakeTransactions);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/pin-money-management/mission-history-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/mission-history-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setCurrentPage(1);
    navigate(
      `/pin-money-management/mission-history-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      })}`
    );
  };

  const tableColumn: ColumnsType<Transaction> = [
    {
      title: "순번",
      dataIndex: "transactionId",
      key: "transactionId",
      align: "center",
      sorter: (a, b) => a.transactionId - b.transactionId,
    },
    {
      title: "거래종류",
      dataIndex: "transactionType",
      key: "transactionType",
      align: "center",
      filters: [
        { text: "미션", value: "미션" },
        { text: "용돈", value: "용돈" },
        { text: "판매", value: "판매" },
      ],
      onFilter: (value, record) => record.transactionType.includes(value as string),
    },
    {
      title: "보낸사람 (아이디 - 프로필)",
      dataIndex: ["senderUserId", "senderProfile"],
      key: "sender",
      align: "center",
      render: (text, record) => `${record.senderUserId} - ${record.senderProfile}`,
    },
    {
      title: "받은사람 (아이디 - 프로필)",
      dataIndex: ["receiverUserId", "receiverProfile"],
      key: "receiver",
      align: "center",
      render: (text, record) => `${record.receiverUserId} - ${record.receiverProfile}`,
    },
    {
      title: "보낸금액",
      dataIndex: "sentAmount",
      key: "sentAmount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
      sorter: (a, b) => a.sentAmount - b.sentAmount,
    },
    {
      title: "받은금액",
      dataIndex: "receivedAmount",
      key: "receivedAmount",
      align: "right",
      render: (amount: number) => amount.toLocaleString(),
      sorter: (a, b) => a.receivedAmount - b.receivedAmount,
    },
    {
      title: "수수료",
      dataIndex: "fee",
      key: "fee",
      align: "right",
      render: (fee: number) => fee.toLocaleString(),
      sorter: (a, b) => a.fee - b.fee,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            <RangePicker onChange={handleDateRangeChange} style={{ marginRight: 10 }} />
          </Grid>
          <Grid item xs={6}>
            {/* <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            /> */}
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.transactionId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            // style={{ cursor: "pointer" }}
            // onRow={(record, _) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/pin-money-management/mission-history-details/${record.transactionId}`);
            //     },
            //   };
            // }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default MissionHistoryList;
