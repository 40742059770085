import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";

// import AntTable from "./components/table";
import { Pagination, PaginationProps, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";
import MDImage from "components/MDImage";
import { formatDate } from "utils/utilDate";
import { formatPhone } from "utils/PhoneString";

interface UserProfile {
  userId: number; // 순번
  name: string; // 아이디
  profileImage: string; // 프로필
  phone: string; // 휴대전화번호
  registrationDate: string; // 가입일
  createdAt: string; // 프로필생성일
  currentPoints: number; // 현재포인트
  bankName: string; // 은행명
  bankAccount: string; // 계좌번호
}

const fakeUserData: UserProfile[] = [
  {
    userId: 1,
    name: "user01",
    profileImage: "profile01.jpg",
    phone: "01012345678",
    registrationDate: "2022-01-15",
    createdAt: "2022-01-16",
    currentPoints: 1500,
    bankName: "BankA",
    bankAccount: "123-456-7890",
  },
  {
    userId: 2,
    name: "user02",
    profileImage: "profile02.jpg",
    phone: "01023456789",
    registrationDate: "2022-02-10",
    createdAt: "2022-02-11",
    currentPoints: 3000,
    bankName: "BankB",
    bankAccount: "234-567-8901",
  },
  {
    userId: 3,
    name: "user03",
    profileImage: "profile03.jpg",
    phone: "01034567890",
    registrationDate: "2022-03-05",
    createdAt: "2022-03-06",
    currentPoints: 4500,
    bankName: "BankC",
    bankAccount: "345-678-9012",
  },
  {
    userId: 4,
    name: "user04",
    profileImage: "profile04.jpg",
    phone: "01045678901",
    registrationDate: "2022-04-01",
    createdAt: "2022-04-02",
    currentPoints: 6000,
    bankName: "BankD",
    bankAccount: "456-789-0123",
  },
  {
    userId: 5,
    name: "user05",
    profileImage: "profile05.jpg",
    phone: "01056789012",
    registrationDate: "2022-05-25",
    createdAt: "2022-05-26",
    currentPoints: 7500,
    bankName: "BankE",
    bankAccount: "567-890-1234",
  },
  {
    userId: 6,
    name: "user06",
    profileImage: "profile06.jpg",
    phone: "01067890123",
    registrationDate: "2022-06-20",
    createdAt: "2022-06-21",
    currentPoints: 9000,
    bankName: "BankF",
    bankAccount: "678-901-2345",
  },
  {
    userId: 7,
    name: "user07",
    profileImage: "profile07.jpg",
    phone: "01078901234",
    registrationDate: "2022-07-15",
    createdAt: "2022-07-16",
    currentPoints: 10500,
    bankName: "BankG",
    bankAccount: "789-012-3456",
  },
  {
    userId: 8,
    name: "user08",
    profileImage: "profile08.jpg",
    phone: "01089012345",
    registrationDate: "2022-08-10",
    createdAt: "2022-08-11",
    currentPoints: 12000,
    bankName: "BankH",
    bankAccount: "890-123-4567",
  },
  {
    userId: 9,
    name: "user09",
    profileImage: "profile09.jpg",
    phone: "01090123456",
    registrationDate: "2022-09-05",
    createdAt: "2022-09-06",
    currentPoints: 13500,
    bankName: "BankI",
    bankAccount: "901-234-5678",
  },
  {
    userId: 10,
    name: "user10",
    profileImage: "profile10.jpg",
    phone: "01001234567",
    registrationDate: "2022-10-01",
    createdAt: "2022-10-02",
    currentPoints: 15000,
    bankName: "BankJ",
    bankAccount: "012-345-6789",
  },
];

function MemberList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(fakeUserData.length);
  const [data, setData] = useState<UserProfile[]>(fakeUserData);
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  const tableColumn: ColumnsType<UserProfile> = [
    {
      title: "순번",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      sorter: (a, b) => a.userId - b.userId,
    },
    {
      title: "아이디",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "프로필",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (text) => <MDImage image={text} alt="profile" style={{ width: 50, height: 50 }} />,
    },
    {
      title: "휴대전화번호",
      dataIndex: "phone",
      key: "phone",
      render: (text: string) => formatPhone(text),
    },
    {
      title: "가입일",
      dataIndex: "registrationDate",
      key: "registrationDate",
      sorter: (a, b) => dayjs(a.registrationDate).unix() - dayjs(b.registrationDate).unix(),
      render: (text) => formatDate(text),
    },
    {
      title: "프로필생성일",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: "현재포인트",
      dataIndex: "currentPoints",
      key: "currentPoints",
      align: "match-parent",
      sorter: (a, b) => a.currentPoints - b.currentPoints,
      render: (currentPoints: number) => currentPoints.toLocaleString(),
    },
    {
      title: "은행명",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "계좌번호",
      dataIndex: "bankAccount",
      key: "bankAccount",
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/member-management/member-details/${record.userId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default MemberList;
