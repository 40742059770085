import { Card, Divider, Grid } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetUserById } from "apis/response";

import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { calculateAge } from "utils/utilDate";
import UserProfileCard from "./components/UserProfileCard";

import MDImage from "components/MDImage";
import { inputToPhone } from "utils/PhoneString";
import { genderString } from "utils";
import dayjs from "dayjs";
import { message, Upload, UploadFile, UploadProps } from "antd";
import { handleUploadCompMultiFiles, props } from "utils/Upload";

function MemberDetails(): JSX.Element {
  const [siteThumbnail, setSiteThumbnail] = useState<UploadFile[]>([]);
  const onChangeSiteThumbnailFile: UploadProps["onChange"] = async ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === "uploading") {
      // 업로드 중인 파일이 있으면 fileList만 업데이트하고 종료
      setSiteThumbnail(newFileList);
      return;
    }

    try {
      const image = await handleUploadCompMultiFiles("image", newFileList, "profile");

      // 파일 업로드 성공 후, 미리보기 이미지를 업데이트합니다.
      // 이때, presignedData.url은 서버에서 반환한 파일의 URL입니다.
      setSiteThumbnail([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: image[0], // 서버에서 반환한 파일의 최종 URL
        },
      ]);
    } catch (error) {
      console.error("File upload failed:", error);
      message.error("파일 업로드에 실패했습니다.");
      // 업로드 실패 시, 현재 파일 리스트에서 해당 파일 제거
      setSiteThumbnail(newFileList.filter((f) => f.uid !== file.uid));
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Upload.Dragger
          style={{ width: "200px" }}
          {...props}
          multiple={false}
          fileList={siteThumbnail}
          onChange={onChangeSiteThumbnailFile}
          accept="image/png, image/jpg, image/jpeg"
          maxCount={1}
        >
          파일 업로드하세요
        </Upload.Dragger>
      </Card>
    </DashboardLayout>
  );
}

export default MemberDetails;
